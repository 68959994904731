.link-youtube-frame {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

@import "../css/Variable.scss";

@media (max-width: 1920px) {
  .navbar_Bottom_left_theme_3 {
    width: 54%;
    padding: 0 15px;
  }

  .card-ecom {
    width: 280px;
  }

  .link-youtube-frame {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 30px;
  }

  .style-filter-1 {
    width: 88px;
    margin: auto;
    margin-top: 10px;
  }
}

@media (max-width: 1550px) {
  .card-ecom {
    width: 165px;
  }

  .link-youtube-frame {
    display: flex;
    justify-content: center;
    width: 100%;
    // flex-direction: column;
    gap: 30px;
  }

  .style-filter-1 {
    width: 70px;
  }
}

@media (max-width: 1150px) {
  .logo_theme_3-secondary {
    height: 50px !important;
  }

  .style-filter-1 {
    width: 60px;
  }

  // .card-image {

  //   height: 47% !important;

  // }

  // .productSearch-section-wrap3 {
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr) !important;
  //   height: 97vh;
  //   overflow: scroll;
  //   padding-bottom: 30px;
  //   width: 95%;
  //   gap: 10px;
  //   margin: auto;
  // }
}

@media (max-width: 950px) {
  .logo_theme_3-secondary {
    height: 48px !important;
  }

  // .card-image {

  //   height: 40% !important;

  // }
}

@media (max-width: 650px) {
  .logo_theme_3-secondary {
    height: 45px !important;
  }

  .navabar_middle_theme_3-logo-box {
    width: 26%;
  }
}

.customer-title-1 {
  height: 450px;
}

.customer-link-detail {
  font-size: 19px;
}

.customer-main-title {
  padding: 30px 0px 10px 0px;
  font-size: 25px;
  text-align: center;
}

.frame-yt-video {
  width: 425px;
  height: 310px;
}

.banner-call-main {
  width: 100%;
}

.img-banner-set {
  height: 90px;
  width: 100%;
}

.img-banner-set2 {
  height: 250px;
  width: 100%;
}

.cards {
  display: flex;
  justify-content: space-evenly;
  // margin-top: 15px;
  background-color: rgb(245 245 245);
  position: relative;
  z-index: 1;
  margin-top: 47px;
}

.banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 450px;
  position: relative;
  margin: 10px;
}

.card-container {
  position: relative;
  width: 16%;
  display: inline-block;
  margin: 15px 10px;
}

.card-ecom {
  width: 100%;
  height: 10vw;
  border-radius: 12px;
  position: relative;
  display: flex;
  border-bottom: 1px solid #ffffff;
  background-color: white;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 500;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.089);
  transform: scale(1);
  transition: transform 0.3s ease-out;
}

.card-image {
  width: 96%;
  height: 75%;
  object-fit: cover;
  border-radius: 12px;
  object-position: center;
}

.card-caption {
  // position: absolute; /* Position absolute */
  bottom: 0;
  /* Align caption to bottom */
  left: 0;
  /* Align caption to left */
  width: 80%;
  /* Full width */
  background-color: rgba(255, 255, 255, 0.5);
  /* Semi-transparent background */
  color: #504e4e;
  /* Text color */
  padding: 4px;
  /* Add padding */
  text-align: center;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  /* Same width as the card */
  background-color: #fff;
  /* Background color of the dropdown */
  box-shadow: 0 2px 4px rgba(66, 66, 66, 0.473);
  /* Shadow effect */
  z-index: 1;
  border-radius: 2px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  /* transform: scale(0.9); */
}

.horizontal-line {
  border-bottom: 1px solid #ededed;
  /* Border style for the horizontal line */
  padding: 2px;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown ul li {
  padding: 10px 16px;
  cursor: pointer;
  height: 32px;
  color: #6c6a6a;
}

.dropdown ul li:hover {
  background-color: #f0f0f0;
  /* Hover background color */
}

.dropdown-content {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.card-ecom:hover {
  border-radius: 0;
  /* Remove border radius on hover */
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.288);
  /* Add box shadow */
  // margin-top: 10px;
  border-bottom: 1px solid #ededed;
}

.dropdown:hover+.card-ecom {
  border-radius: 0;
  /* Remove border radius on hover */
  // transform: scale(0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.288);
  /* Add box shadow */
  // margin-top: 10px;
  border-bottom: 1px solid #ededed;
}

.Category-banner-img {
  width: 100%;
  height: 300px !important;
  object-fit: cover;
}

.card-product-main-container11 {
  position: relative;
  width: 24vw;
}

.flex-forgot-otp {
  display: flex;
}

.Card-product-type-11 {
  position: relative;
  box-sizing: border-box;
  width: 98%;
  margin: 10px;
  min-height: 295px;
  height: auto;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(235 235 235);
  //box-shadow: 12px 17px 51px rgba(150, 150, 150, 0.22);
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: flex-start;
  -webkit-user-select: none;
  user-select: none;
  font-weight: bolder;
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  padding-top: 10px;
  border-radius: 8px;
}

.Card-product-type-11:hover {
  border: 1px solid rgb(219, 219, 219);
  transition: all 0.4s;
  box-shadow: 12px 17px 51px rgba(150, 150, 150, 0.22);
}

.Card-product-type-1-end1 {
  height: 30px;
  background: linear-gradient(to right, rgb(249 234 186), rgb(239 239 239));
  margin-top: 10px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-weight: 100;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 0px;
  color: #a57c00;
  height: 40px;
}

.card-product-price11 {
  margin: 0;
  font-size: 14px;
  font-weight: 100;
  // color: #000042;
  line-height: 18px;
  font-weight: 800;

  color: #4caf50;
}

.card-product-size11 {
  margin: 0;
  font-size: 13px;
  font-weight: 100;
  color: #6d6d7f;
  line-height: 18px;
  font-weight: 600;
  padding: 5px 0px 5px;
}

.Card-product-type-1-rating-box11 {
  width: max-content;
  padding: 5px 12px 5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  margin-bottom: 5px;
  border-radius: 15px;
  margin-left: 8px;
  background: #eaeaff;
}

.style-filter-11 {
  width: 65px;
  height: 50px;
  margin: 3px;
  border: 1px solid rgb(177 177 177);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  cursor: pointer;
}

.lenses-show-multiple {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  text-align: -webkit-center;
}

.ProductDetailPage-left-theme3 {
  width: 85%;
  height: 100%;
  display: flex;
  // margin-left: 20px;
}

.ProductDetailPage-smallimg-theme3 {
  width: 95%;
  height: max-content;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  outline: 1px solid rgb(211, 211, 211);
  border-radius: 8px;
  overflow: hidden;
}

.ProductDetailPage-imagebox2-theme3 {
  width: 100%;
  padding: 20px 0px;
  margin-left: 20px;
}

.modal-back-alert-theme3 {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 18%);
  z-index: 101;
  animation: modalback 500ms;
}

.OpticalField-modal {
  width: 50%;
  border: 1px solid rgb(94, 94, 94);
  color: rgb(0, 0, 0);
  background-color: white;
  border-radius: 15px;
  position: fixed;
  height: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modalslide 1000ms;
}

.increase_modal {
  width: 50%;
  max-height: max-content;
  // overflow: scroll;
}

.tech-width-info {
  width: 45%;
}

.Modal-Display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 410px;
  margin-top: 20px;
}

.close_modal-theme3 {
  position: absolute;
  color: #aaaaaa;
  float: right;
  font-size: 24px;
  top: -3px;
  font-weight: bold;
  cursor: pointer;
  right: 9px;
  width: 20px;
}

.lense-view-container {
  border: 1px solid darkgrey;
  border-radius: 5px;
  display: flex;
  padding-top: 10px;
}

.ProductDetailPage-imagebox1-theme3 {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin: 10px 7px;
  gap: 10px;
}

.ing-size-xx-xl-theme3 {
  height: max-content;
  width: 550px;
  margin-top: 20px;
}

.lense-view-container-theme3 {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  display: flex;
  padding: 5px;
  height: 80px;
  width: 80px;
}

.product-detail-button-box-theme3 {
  margin: 10px 20px;
  width: 95%;
}

.product-detail-button-theme3 {
  width: 90%;
  height: 50px;
  border: none;
  font-size: 17px;
  background-color: $Theme-color;
  border-radius: 15px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(211, 211, 211);
  margin-bottom: 6px;
}

.product-detail-button-theme3-cart-button {
  font-size: 17px;
  width: 100%;
  color: white;
  text-align: center;
  /* border-radius: 20px; */
  cursor: pointer;
  margin: auto;
  height: 100%;
  font-weight: 500;
  // background: linear-gradient(
  //   to right,
  //   #1c929a,
  //   rgb(21 190 201)
  // ); // for lens2cart
  background: linear-gradient(90deg, $Theme-color, lighten($Theme-color, 8%));
  // background: linear-gradient(to right, #e44702, #943409); // for pintu
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.cartpage-theme3 {
  display: flex;
  min-height: 86vh;
  width: 80%;
  background-color: #f7f7f7;
  padding: 0 7%;
  justify-content: center;
}

.cartpage-left-theme3 {
  display: flex;
  width: 65%;
  flex-direction: column;
}

.cartpage-right-theme3 {
  display: flex;
  width: 30%;
  padding: 5px 0px;
  flex-direction: column;
}

.review-box-2-theme3 {
  // height: 320px;
  height: max-content;
  box-shadow: 10px 10px 10px rgb(240 240 240);
  position: relative;
}

.review-box-options--theme3 {
  padding-bottom: 8px;
  padding-top: 10px;
  padding-left: 10px;
  margin-right: 15px;
  padding-right: 10px;
  border-bottom: 1px dashed rgb(196, 196, 196);
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(100, 100, 100);
  font-weight: 600;
}

.cartpage-heading-theme3 {
  font-size: 20px;
  // font-family: auto;
  width: 90%;
  margin: 2px auto;
  margin-top: 80px;
  color: #000042;
  font-weight: 600;
}

.heading-panel-1-theme3 {
  width: 80%;
  font-size: 18px;
  font-weight: 500;
  color: #000042;
}

.pricebox-type-btn-theme3 {
  width: 100%;
  height: 65px;
  font-size: 18px;
  background-color: rgb(17, 218, 172);
  border: 0;
  color: rgb(0 0 66);
  border-radius: 50px;
  margin-top: 15px;
  transition: all;
  transition-duration: 300ms;
  border-top: 1px solid rgb(211, 211, 211);
  cursor: pointer;
  font-weight: 600;
}

.navbar-cart-link-theme3 {
  color: #424242;
}

.card-wish-theme3 {
  width: 310px;
  height: 325px;
  margin: 0 auto;
  background-color: #f8fbfe;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.288);
}

.card-wish-title-theme3 {
  display: flex;
  justify-content: center;
  height: 30px;
  justify-items: baseline;
  background: #020202;
  color: white;
  font-size: 19px;
  align-items: baseline;
  padding: 6px;
  border-radius: 0px;
}

.favourite-box-admin-WishList-theme3 {
  width: 100%;
  position: relative;
  margin: auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgb(241, 241, 241);
  background-color: white;
  border-radius: 2px;
  transition: all;
  transition-duration: 300ms;
  border-bottom: 1px solid rgb(211, 211, 211);
}

.favourite-box-container-theme3 {
  height: 155px;
  overflow: scroll;
}

.tools {
  display: flex;
  align-items: center;
  padding: 9px;
}

.circle {
  padding: 0 4px;
}

.box {
  display: inline-block;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 1px;
  border-radius: 50%;
}

.favourite-img-container-theme3 {
  width: 50px;
  padding: 0px 10px !important;
}

.wish-list-del-icon {
  width: 12px;
  height: 12px;
  padding: 5px 5px 7px 7px;
  background-color: #009688;
  color: white;
  display: flex;
  align-items: baseline;
}

.review-box-image2-theme3 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-box-detail2-theme3 {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: space-evenly;
}

.favourite-box-admin-WishList-theme3:hover {
  background-color: #ffe186;
}

.favourite-box-container-scroll-theme3 {
  height: 215px;
  overflow-y: scroll;
}

.favourite-box-container-scroll-theme3 {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: 7px;
}

.review-box-name21-theme3 {
  width: 90%;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
  color: #696969;
}

.review-box-section-icon-theme3 {
  width: 10%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.review-box-detail-line-admin-1 .wish-list-del-icon {
  display: none;
  /* Hide the icon by default */
}

.favourite-box-admin-WishList-theme3:hover .wish-list-del-icon {
  display: inline;
  cursor: pointer;
  /* Show the icon on hover */
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 1000;
  transition: width 2s, height 2s, transform 2s;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
}

.slider-container100 {
  position: fixed;
  top: 0px;
  right: -100%;
  overflow: scroll;
  overflow-x: hidden;
  height: 100vh;
  width: 55%;
  background-color: white;
  border-left: 1px solid #eeeeee;
  transition: right 0.9s ease-in-out;
  z-index: 101;
}

.slider-container100.open {
  right: 0%;
}

.slider-top-bar-ad {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.slider-display-allow {
  display: flex;
  justify-content: space-between;
  padding: 25px 30px 0px 30px;
}

.slider-cross-btn {
  position: absolute;
  right: 3%;
  font-size: 22px;
}

.slider-top-head-nm {
  font-size: 28px;
  // font-family: serif;
  margin-left: 15px;
  color: #000042;
  font-weight: 500;
  font-family: auto;
}

.slider-head-gm {
  display: flex;
  align-items: center;
  height: 30px;
}

.slide1-left {
  font-size: 17px;
}

.buy-with-lens-option-slider-theme3 {
  width: 94%;
  margin: 12px auto;
  min-height: 62px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 15px;
  box-shadow: 0px 0px 15px #dddddd;
  background-color: white;
  border-radius: 15px;
  transition: all;
  transition-duration: 200ms;
  cursor: pointer;
  position: relative;
  color: #000042;
  font-size: 15px;
  font-weight: 600;
}

.lens-option-pararaph-theme3 {
  font-size: 13px;
  // margin-left: 5px;
  font-weight: 600;
  color: #66668e;
}

.buy-with-lens-option-slider-theme3:hover {
  scale: 1.02;
  box-shadow: 0px 0px 15px #cfcfcf;
}

.slide-sub-amount-bar {
  width: 100%;
  font-size: 18px;
  color: #000042;
  font-weight: 500;

  border-radius: 10px;
}

.lens-option-top-theme3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
}

.slider-lens-picture {
  height: 35px;
  padding: 10px;
}

.Anti-class-add {
  color: #000042;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 10px;

}

.slide-mat-desc span {
  display: flex;
  flex-direction: column;
  color: #000042;
  width: 100%;
  padding-left: 5px;
}

.slide-mat-free-gif {
  font-size: 20px;
  font-weight: 300;
  color: rgb(0, 190, 238);
}

.slide-ofr-sectn-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(to right, rgb(250 250 250), rgb(255 244 208));
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.container {
  position: relative;
  width: 300px;
  /* Adjust based on your needs */
  height: 200px;
  /* Adjust based on your needs */
  overflow: hidden;
}

.component-ecom {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

// .first {
//   background-color: lightblue;
// }

.second {
  // background-color: lightgreen;
  transform: translateX(100%) !important;
}

.container .component.exit {
  transform: translateX(-100%);
}

.container .component.enter {
  transform: translateX(0);
}

.Flex-ImagesTheme_Lc {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.Flex-ImagesTheme_Lc-theme-2 {
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

// .lense-view-container-theme-2 {
//   width: 95%;
// }
// ************************ Login Page*****************************

.Login-Img_Lc {
  width: 100%;
}

.SignIn-Padding-Lc {
  padding: 15px 30px;
}

.None-Padding-Lc {
  padding: none !important;
}

.position-icon-pw {
  position: absolute;
  top: 63%;
  right: 60px;
  transform: translateY(-50%);
  width: 30px;
  height: auto;
}

.resendNote {
  font-size: 12px;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.resendBtn {
  background-color: transparent;
  border: none;
  color: rgb(127, 129, 255);
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 700;
}

.signup-pwd-top {
  top: 59%;
}

.Sign-reenter-top {
  top: 70%;
}

.forgot-height {
  height: max-content;
}

.wish-list-del-page {
  width: 20px;
  height: 20px;
  padding: 1px;
  border-radius: 50%;
  right: 10px;
  position: absolute;
  cursor: pointer;
  top: 9px;
}

.SendOTP-width {
  width: 69%;
  margin-right: 10px;
}

.SendOTPButton {
  width: 40%;
  height: 35px;
  border: none;
  background-color: rgb(0 186 198);
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  transition-duration: 0.2s;
}

.mainHeading-OTP {
  font-size: 14px;
  color: rgb(15, 15, 15);
  font-weight: 700;
  text-align: center;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.otp-input {
  background-color: rgb(228, 228, 228);
  width: 35px;
  height: 35px;
  text-align: center;
  border: none;
  border-radius: 7px;
  caret-color: rgb(127, 129, 255);
  color: rgb(44, 44, 44);
  outline: none;
  font-weight: 600;
}

.otp-input:focus,
.otp-input:valid {
  background-color: rgba(127, 129, 255, 0.199);
  transition-duration: 0.3s;
}

.Verify-height {
  height: max-content;
}

.Changepwd-height {
  height: 240px;
}

.change-pwd-top {
  top: 48%;
}

.Confirm-pwd-top {
  top: 70%;
}

.select-address {
  width: 100%;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid rgba(55, 65, 81, 1);
  color: rgb(20, 20, 20);
}

.Qty-ttl {
  font-size: 11px;
  color: rgb(247, 244, 244);
  font-weight: 700;
  text-align: center;
  background-color: rgb(50, 156, 146);
  border-radius: 19px;
  position: absolute;
  display: flex;
  top: -10px;
  right: -20px;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
}

.wish-list-popup-all-clean {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.all-clean-btn-theme3 {
  color: white;
  background: #009688;
  border: 1px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.nav-space-secondary {
  width: 100%;
  height: 100px;
}

.logo_theme_3-secondary {
  height: 50px;
}

.Flex-Right-Arrow-Lc {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.right-arrow-iconLC {
  margin-top: 5px;
}

.Input-Box-LeftEye {
  padding: 0px;
  width: 47%;
  border-radius: 4px;
}

.Left-Eye-select_lc {
  width: 100%;
  height: 50px;
  font-size: 14px;
  outline: none;
  background-color: transparent;
  white-space: pre-wrap;
  cursor: pointer;
  border: 1px solid #c5c0c0;
  color: rgb(51, 51, 51);
  border-radius: 5px;
}

.Table-Main_Lc {
  width: 23vw;
  border-collapse: collapse;
  margin-top: 15px;
}

th {
  border: none;
}

td {
  border: none;
}

table {
  border: none;
}

.fa-circle-question {
  margin: 0px 5px;
}

.Power-Color-Lc {
  color: $Theme-color;
}

.description {
  position: absolute;
  font-size: 12px;
  top: 66%;
  left: 0;
  width: 235px;
  background-color: black;
  color: white;
  font-weight: normal;
  border-radius: 5px;
  display: none;
  z-index: 1;
}

.question-mark:hover+.description {
  display: block;
}

.ul-padding-left {
  padding-left: 15px;
}

.li-margin-bot:before {
  margin-bottom: 10px;
  // content: "�";
  margin-right: 5px;
  position: absolute;
  left: 13px;
}

.navabar_middle_theme_33 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin: 4px 0px 0px;
  height: 80px;
  background: #ffffff;
}

.form-container-login-theme3 {
  width: 27%;
  min-width: 350px;
  height: 555px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  position: fixed;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.cartpage-theme4 {
  height: max-content;
  min-height: 20vh;
  width: 100%;
  background-color: #f7f7f7;
  padding: 0 7%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
}

.cartpage-theme4 .wish-side-ihdds2 {
  // margin: auto;
}

.cartpage-theme4 .cartpage-pricebox-container-theme3-pt {
  margin: auto;
}

.cartpage-pricebox-container-theme3 {
  width: 220px;
  margin-top: 10px;
  background-color: #ffffff;
  min-height: 5vh;
  padding: 12px;
  border-radius: 15px;
  transition: all;
  transition-duration: 300ms;
}

.pricebox-wishlist-container {
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
  color: #4b4a4a;
  cursor: pointer;
  flex-direction: column;
  border-radius: 12px;
}

.pricebox-wishlist-container img {
  width: 100px;
  margin-bottom: 5px;
}

.Blank-cart-page-btn {
  width: 250px;
}

.p-graph-head-title {
  font-size: 38px;
  text-align: center;
}

.black-codesdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8%;
  height: 80vh;
  justify-content: center;
}

.wish-side-ihdds {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
}

.wish-side-ihdds2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.left-right-arrow {
  font-size: 18px;
  color: $Theme-color;
  padding-left: 20px;
}

.head-body-arrow {
  color: $Theme-color;
  padding-left: 15px;
}

.cartpage-pricebox-container-theme3 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 70%;
  background: #f7f7f7;
  justify-items: center;
}

.cartpage-pricebox-container-theme3-pt {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 80%;
}

.pricebox-wishlist-container {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  background: white;
  max-width: 75%;
  min-width: 200px;
  margin: 10px auto;
}

.left-right-arrow {
  cursor: pointer;
}

.cartpage-high-theme3 {
  display: flex;
  min-height: max-content;
  width: 100%;
  background-color: #f7f7f7;
  padding: 30px 0px;
  justify-content: center;
}

.price-show-wl-3 {
  color: $Theme-color;
  font-size: 14px;
}

.title-name-wl-3 {
  color: #000042;
  font-size: 16px;
  font-weight: 500;
}

.Table-Sub-Main_Lc {
  width: 21vw;
  border-collapse: collapse;
  margin-top: 15px;
}

.ContactLens-Font-Lc {
  background: linear-gradient(0deg,
      rgba(191, 238, 239, 0.08),
      rgba(191, 238, 239, 0.08)),
    rgb(255, 255, 255);
  padding: 10px;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 15px;
}

.Flex-User-Selected {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  gap: 8px;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.L-Elipse-Lc {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  min-height: 1em;
  line-height: 1;
  letter-spacing: 0.4px;
  color: rgb(255, 255, 255);
  padding: 0.7em;
  min-width: 1em;
  border-radius: 50%;
  background-color: rgb(255, 210, 143);
  text-transform: uppercase;
  text-align: center;
}

.Selected-Btn-Lc {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: rgb(0, 185, 198);
  border: none;
  background: none;
  height: max-content;
  cursor: pointer;
}

.user-lc-font {
  font-size: 15px;
  letter-spacing: 0.6px;
  color: rgb(60, 60, 60);
  margin: 0px;
}

.Added-font-Lc {
  font-size: 12px;
  letter-spacing: 0.6px;
  color: rgba(60, 60, 60, 0.38);
  margin: 0px;
}

.Flex-Technical-Arrow {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  //border-bottom: 1px solid #dadada;
}

.Technical-Down-Lc {
  font-size: var(--fs-15);
  letter-spacing: 2px;
  color: rgb(51, 51, 51);
  font-weight: 600;
}

.Features-Technical-Box {
  margin: 5px 12px;
  font-size: 13px;
  line-height: 25px;
}

.Font-TechInfo_Lc {
  padding: 5px;
  font-size: 15px;
  text-align: justify;
  vertical-align: top;
  width: 100%;
  padding: 10px 0px;
}

.arrow-box-theme2 {
  width: 100%;
  height: 50px;
  background-color: #020202;
  position: relative;
}

.check-out-main-head {
  border: 1px dashed gray;
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background: #e7ffe8;
}

.cart-shipping-address-co-1 {
  background-color: #329c92;
  color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 18px;
}

.cart-add-ads-1 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.cart-add-ads-3 {
  font-size: 17px;
}

.checkout-step-two {
  color: #fff !important;
  float: left;
  line-height: 18px;
  padding: 6px 10px 5px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  width: 118px;
  border: 0 solid #000;
  height: 45px;
  background: #00b9c6;
  font-size: 15px;
  display: block;
  border: none;
  padding: 9px 10px 7px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 5px !important;
  min-width: 143px;
}

.chech-addrss-add-4 {
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 18px;
  border-bottom: 1px solid rgb(165, 164, 164);
}

.img-data-save {
  height: 57px;
  position: absolute;
  text-align: center;
  left: 40%;
}

.nav-space1 {
  width: 100%;
  height: 80px;
  background-color: #f7f7f7;
}

.nav-space2 {
  width: 100%;
  height: 80px;
  background-color: #f7f7f7;
}

.Color-ShowAllInfo-Lc {
  color: $Theme-color;
}

.Flex-modal-width {
  display: flex;
}

.Modal-Title-Lc {
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 5px 0px;
  font-family: "Rajdhani-Regular";
  border-bottom: 1px solid #f4f4f4;
}

.Modal-Lc-theme3 {
  height: max-content;
  width: 100%;
}

.Font-ModalInfo_Lc {
  padding: 5px;
  font-size: 12px;
  text-align: justify;
  vertical-align: top;
}

.Flex-Visit-Store {
  display: flex;
  justify-content: center;
}

.Visit-Near-width {
  width: 45%;
}

.Pincode-width-Lc {
  border: 1px solid #dbdbdb;
  padding: 5px 10px;
  height: 30px;
  border-radius: 4px;
  font-size: 13px;
  outline: none;
  width: 88%;
}

.Flex-input-box-delivery {
  width: 98%;
  display: flex;
  justify-content: center;
}

.Check-Btn-Lc {
  background: none;
  border: none;
  color: $Theme-color;
  font-size: 15px;
  cursor: var(--pointer);
  position: absolute;
  font-family: var(--font-default-book);
  right: 33px;
  margin-top: 9px;
}

.Flex-Button-MoreWrite {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: row;
}

.More-Review-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: $Theme-color;
  min-width: 47%;
  min-height: 45px;
  padding: 10px;
  background-color: transparent;
  border: 1px solid $Theme-color;
  border-radius: 10px;
  cursor: pointer;
  margin-right: auto;
  text-transform: uppercase;
}

.write-Review-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: white;
  min-width: 47%;
  min-height: 45px;
  padding: 10px;
  background-color: $Theme-color;
  border: 1px solid $Theme-color;
  border-radius: 10px;
  cursor: pointer;
  margin-right: auto;
}

.Contact-Review-Padding-Lc {
  padding: 10px;
}

.Font-Contact-Lenses_Lc {
  font-size: 15px;
  font-weight: 500;
}

.star-color-Lc {
  color: #f3a40e;
}

.slide-add-cart-btn {
  width: 250px;
  height: 50px;
  border: none;
  font-size: 17px;
  background-color: $Theme-color;
  border-radius: 15px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(211, 211, 211);
  margin-bottom: 6px;
  margin-left: 50px;
}

//*****************************Find The Perfect Fit***************************************

.Flex-Find-Perfect-Lc {
  display: flex;
  justify-content: space-evenly;
}

.Find-Image-width-Lc {
  width: 47%;
}

.Four-Banner-Lc {
  width: 100%;
  margin: auto;
  margin-top: 10px;
}

.Find-Under-width-Lc {
  width: 96%;
  display: flex;
  margin: auto;
  margin-top: 10px;
}

.Under-img {
  width: 98%;
}

.Contact-Img-Lc {
  width: 97%;
}

.filterclrset {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px solid gray;
  margin-right: 10px;
}

.Order-Details-Track {
  width: 20%;
  padding: 10px;
  border: 1px solid rgb(223, 223, 223);
  margin: auto;
  margin-top: 18px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
}

.Flex-Price-Breakup {
  display: flex;
  justify-content: space-between;
}

.Order-width-details {
  width: 50%;
}

.Delivery-Details-lc-ord {
  width: 94%;
  margin: auto;
  margin-top: 10px;
}

//***************************ProductDetailTheme1Lc********************************

.Product-Font-Top_Lc {
  font-size: 15px;
  line-height: 28px;
  color: #a39f9f;
  margin: 0px 8px;
}

.Order-phone_Lc {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.Give-Missed-Lc {
  font-size: 11px;
  text-align: center;
}

.OrderOnPhone-line_Lc {
  border: 0.5px solid #efefef;
  width: 94%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Select-Power-Lc {
  font-size: 18px;
  margin: 0px 10px;
}

.Flex-Radio-Button-Lc {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.Radio-font-lc {
  font-size: 15px;
  margin-left: 10px;
}

.Border-Box-Lc {
  width: 95%;
  height: max-content;
  border: 1px solid rgb(223, 223, 223);
  margin: auto;
  margin-top: 11px;
  padding-bottom: 15px;
  border-radius: 10px;
}

.Radio-Input-Box-Lc {
  width: 5%;
  color: $Theme-color;
}

.Box-padding-themeLc {
  padding: 20px;
}

.Flex-WishList-Lc {
  display: flex;
}

// .fa-heart {
//   top: 5px;
// }

// .AboutEyePowerContainer{
//***********************Shipping Address*****************************
.shipping-Address-flex {
  display: flex;
  justify-content: center;
}

.flex-Shipping-address {
  display: flex;
  justify-content: center;
  width: 83%;
  gap: 20px;
  margin-top: 20px;
}

.Flex-input-shipping {
  display: flex;
  justify-content: center;
  width: 80%;
  padding: 10px;
}

.Save-Address-theme3 {
  font-size: 18px;
  // font-family: auto;
  width: 76%;
  margin: 2px auto;
  margin-top: 20px;
  display: flex;
}

.Input-Box-shipping-Lc {
  display: flex;
  justify-content: center;
  width: 48%;
  padding: 10px;
}

.Input-shipping-name {
  width: 98%;
  height: 50px;
  border-radius: 15px;
  border: 1px solid #dedada;
  outline: none;
  font-size: 14px;
  padding: 0px 10px;
}

.Flex-Gender-Shipping {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
}

.modifyTextLabel {
  position: absolute;
  color: #8d8a8a;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  left: 20px;
  top: -2px;
  font-size: 14px;
}

.Flex-Checkout-powers {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #ffe092;
  background-color: #f7f1de;
  width: 75%;
  padding: 10px;
  border-radius: 10px;
}

.Paragraph-checkout-Font {
  width: 90%;
}

.Flex-Home-Work-Other {
  display: flex;
  justify-content: flex-start;
  width: 76%;
  margin-bottom: 50px;
}

.Home-Icon-Box-Lc {
  border: 1px solid grey;
  border-radius: 20px;
  width: 15%;
  display: flex;
  justify-content: center;
  padding: 5px;
  outline: none;
  align-items: center;
  background-color: white;
  margin-right: 30px;
  font-size: 13px;
  color: #000042;
  cursor: pointer;
}

.Home-Icon-Box-Lc.active {
  background-color: #000042;
  /* Background color when selected */
  border-color: #333;
  /* Border color when selected */
  color: #ffffff;
}

.Top_font-Shipping-Add {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.15px;
  cursor: pointer;
  color: #000042;
}

.add-color-Shipping-add {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #0095fb;
  cursor: pointer;
}

.disable-Shipping-add {
  cursor: not-allowed;
  opacity: 50;
  color: #c2b5b5;
}

.Arrow-Margin-right {
  margin-right: 10px;
}

.gender-flex-add {
  display: flex;
  align-items: center;
}

.input-radio-shipping {
  margin: 5px;
  font-size: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px solid rgb(92, 92, 134);
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  min-width: 20px;
}

.Label-Gender-Lc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000042;
}

.Border-Saved-Address-Lc {
  border: 1px solid #072249;
  border-radius: 12px;
  width: 75%;
  padding: 10px;
  height: 90px;
  background-color: rgb(245, 245, 255);
  transition: all 0.2s ease-in-out 0s;
  margin-bottom: 16px;
  border: 1px solid rgb(115, 115, 151);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  font-family: var(--font-lksans-bold);
  overflow-wrap: break-word;
}

.Flex-Shipping-Saved {
  display: flex;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  margin-bottom: 10px;
}

.correct-icon-Lc {
  font-size: 25px;
  color: #000042;
}

.Home-icon-Lc {
  font-size: 20px;
  margin-right: 10px;
}

.Shipping-Saved-Home {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 4px;
  color: #000042;
}

.Address-font-shipping {
  font-weight: 500;
  font-size: 14px;
}

.changes-Btn-Lc {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 25px;
}

.Delete-Btn-lc {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0px;
  cursor: pointer;
  text-decoration: underline;
}

.label-input-sa1:valid~label {
  transform: translateY(21%) scale(0.7) !important;
  background-color: #ffffffee !important;
  padding: 0 0.1em 0 !important;
  color: #7e7c7c !important;
  font-size: 13px;
}

.modify_p_text7 {
  position: absolute;
  color: #4b4848;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  left: 31px;
  top: -18px;
  font-size: 14px;
}

.modify_im_input14 {
  border: 1.5px solid #ccc;
  color: #555;
  font-size: 14px;
  word-wrap: normal;
  text-transform: none;
  outline: none;
  border-radius: 2px;
}

.modify_im_input14:valid~label {
  transform: translateY(21%) scale(0.7);
  background-color: #ffffffee !important;
  padding: 0 0.1em 0 !important;
  color: #7e7c7c !important;
  font-size: 13px;
}

.Flex-Add-New-Lc {
  display: flex;
  justify-content: space-between;
  width: 79%;
  border: 1px solid grey;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  padding: 24px;
  background: var(--white);
  border-radius: 10px;
  cursor: pointer;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
}

.New-Address-shipp {
  font-size: 14px;
  font-weight: 600;
  color: #000042;
}

.right-arrow-font {
  font-size: 20px;
  color: #000042;
}

.iconHouse-right-Lc {
  margin-right: 10px;
}

.Select-Address-shipp {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000042;
  margin-bottom: 21px;
}

.Saved-Add-shipp {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgb(51, 51, 104);
  margin-bottom: 16px;
}

.Heading {
  font-size: 18px;
}

.RowContainer {
  display: flex;
  font-size: 18px;
  align-items: center;
  margin-bottom: 30px;
}

.AboutEyePowerContainer {
  padding: 20px;
  height: 59vh;
}

.SubHeading {
  margin-left: 10px;
  color: #000042;
  font-weight: 500;
}

.cart-button {
  display: flex;
  justify-content: flex-end;
}

.style-filter-box-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
  width: 90%;
  margin: 0 auto;
}

.productSearch-section-wrap3 {
  grid-gap: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // height: 102vh;
  // overflow: scroll;
  padding-bottom: 30px;
  scrollbar-width: none;
  justify-items: center;
  width: 94%;
  gap: 5px;
  margin: auto;
}

.style-filter-x2 {
  width: 85%;
  height: 85%;
  margin: 3px;
  border: 1px solid rgb(177, 177, 177);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  cursor: pointer;
}

.CheckBox-input-click {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  width: 23px;
  height: 21px;
  background-color: #0000;
  border-radius: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.38);
}

.CheckBox-input-click::after {
  display: block;
  content: "";
  width: 4px;
  height: 10px;
  border-width: 0px 1px 1px 0px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  transform: rotate(45deg) translate(-1px, -1px);
}

.Flex-Input-Address-Lc {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  padding: 10px;
}

//***************************Payment Methods ****************************

.Flex-Apply-Coupon {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e2e2e2;
  outline: none;
  background-color: white;
  // padding: 10px;
  width: 74%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  border-radius: 12px;
  cursor: pointer;
  padding: 16px;
}

.flex-Radio-Coupon {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.Radio-margin-auto-Lc {
  display: flex;
  align-items: center;
}

.Input-Radio-Coupon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(92, 92, 134);
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  min-width: 20px;
}

.Apply-Coupon-Head {
  font-style: normal;
  font-size: 14px;
  display: block;
  letter-spacing: -0.02em;
  color: #000042;
  flex: 0 0 auto;
  line-height: 20px;
  margin-left: 16px;
  order: 0;
  align-self: stretch;
}

.cashbacks-offers {
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: rgb(102, 102, 142);
  margin-left: 15px;
}

.Flex-Angle-right {
  display: flex;
  align-items: center;
  width: 5%;
}

.Upi-Paddin-QR {
  padding: 16px;
  width: 74%;
}

.flex-UPI-Pay {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.Power-margin-bottom {
  margin-bottom: 35px;
}

.GooglePay-Img-lc {
  width: 47px;
  height: 32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(226, 226, 238);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7px 16px;
}

.google-Pay-img {
  width: 46px;
  height: 32px;
  object-fit: contain;
  display: block;
  margin: 0px;
  padding: 0px;
}

.cartpage-bottom {
  margin-bottom: 30px;
}

//****************************Apply Coupon Slider*******************************

// .Slider-coupon-width{
//   width: 60%;
// }

// .Slider-coupon-width{
//   right: -25%;
// }

.Gaping-Coupon-code {
  padding: 10px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  // overflow-y: scroll;
}

.Coupon-code-width-Lc {
  width: 100%;
}

.Flex-Coupon-code-lc {
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}

.Input-Box-Coupon {
  width: 80%;
  height: 35px;
  border-radius: 8px;
  text-transform: uppercase;
  border: 1px solid rgb(162, 162, 184);
  font-size: 16px;
  padding: 16px;
  box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;
  user-select: initial;
}

.Apply-CouponBtn-Lc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  gap: 8px;
  min-width: 87px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  height: 30px;
  border: 1.5px solid #000042;
  color: #000042;
  border-radius: 12px;
  cursor: pointer;
}

.Moment-Coupon-alert {
  display: flex;
  align-items: center;
}

.Coupons-Moment {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background: rgb(247, 241, 222);
  font-weight: bold;
  color: #333368;
  font-size: 16px;
  border-radius: 10px;
}

.width-Default-Address {
  width: 75%;
}

//*******************Lens Prescription*************************

.Usage-Topic-Lc {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000042;
  text-transform: capitalize;
  font-synthesis: none;
}

.Monthly-Disposable-Lc {
  color: rgb(51, 51, 51);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.Buying-For--User {
  padding-bottom: 8px;
  padding-top: 10px;
  padding-left: 10px;
  margin-right: 15px;
  padding-right: 10px 0px;
  border-bottom: 1px dashed rgb(196, 196, 196);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // color: rgb(100, 100, 100);
  color: #000042;
  font-weight: 500;
  z-index: 99;
}

.Table-Border-Prescription {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  outline: none;
  width: 96%;
}

.Table-Header-Contactlens {
  background-color: rgb(245, 245, 255);
  color: #15153a;
  text-align: center;
  font-weight: 500;
}

.table-Main-contact-Lc {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid purple;
  border-radius: 8px;
  letter-spacing: -0.02em;
  line-height: 18px;
  overflow: hidden;
  font-size: 12px;
  background-color: white;
  display: table;
}

.Lens-user-Mleft {
  margin-left: 25px;
}

.card-cart {
  overflow: scroll;
}

.Head-top-contect {
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  cursor: pointer;
}

.Card-product-type-1-color-box2 {
  display: flex;
  cursor: pointer;
}

.lens-type-icon-xl {
  position: relative;
  right: -25px;
}

.lens-product-type-x1 {
  width: 90%;
  margin-left: 10px;
}

.lens-product-type-x2 {
  width: 10%;
}

.favourite-head-box-xl {
  display: flex;
  width: 100%;
}

.address-name-x {
  color: #020202;
}

.address-text-xl {
  margin: 0;
  font-size: 14px;
  color: #a7a7a7;
}

.Slider-Images-width {
  width: 76%;
}

// .slick-slide{
//   width: 220px !important;
// }

.slick-prev {
  left: -45px !important;
}

.slick-next {
  right: -15px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
}

.slick-slide {
  // width: 380px !important;
}

.wishlist_background {
  width: 75%;
  height: 130px;
  background-size: 90% !important;
}

.flex-forgot-otp {
  display: flex;
}

.title-name-wl-3 p {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 5px;
}

// ************************

/***********************Product Detail Theme 2******************************/

.increase_modal_theme2 {
  width: 60%;
  height: max-content;
}

.modal-imgage-theme2 {
  margin-top: 0px;
}

.modaldisplay-parent {
  padding: 15px;
  width: 60%;
  margin: auto;
}

/************************************************************/
.Card-product-type-1-cart5 {
  height: 30px;
  background: linear-gradient(to right, rgb(249 234 186), rgb(239 239 239));
  margin-top: 10px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #a57c00;
  height: 40px;
}

.cart5-price-clr {
  color: #000042;
}

.cart5-product-type:hover {
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 3px 16px 0px;
}

.heart-cart5 {
  color: #ff1e1f;
  font-weight: 100;
}

.heart-cart5:hover {
  color: red;
}

.mrp-cart5-clr {
  color: #000042;
}

.Lens-color-cart3page {
  color: #000042;
  font-size: 14px;
}

.eye-form-radius {
  border-radius: 0px;
}

.MyEyePower {
  font-family: auto;
  font-weight: 500;
  color: #000042;
}

.top-subtotal {
  margin-top: 50px;
}

.flex-subtotal-cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-cart-top {
  margin-top: 50px;
}

.feature-image-coating {
  width: 50%;
}

.img-coating-features {
  height: 100%;
  width: 80%;
}

.flex-buying-item {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.Buy-now-box {
  width: 48%;
}

.Add-Power-box {
  width: 48%;
  background-color: white;
  color: #00bac6;
  border: 1px solid #00bac6;
}

/* Container */
.video-slider-container {
  background: #fff092;
  padding: 10px;
  text-align: center;
  height: 310px;
  display: none;
}

/* Title */
.video-slider-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

/* Swiper customization */
.video-slider {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

/* Each Slide */
.video-slide {
  display: flex !important;
  justify-content: center !important;
}

/* Video Frame Wrapper */
.video-frame-wrapper {
  width: 100%;
  max-width: 500px;
}

/* Responsive Video */
.video-frame {
  width: 100%;
  height: 250px;
  border-radius: 8px;
}

.custom-prev,
.custom-next {
  position: absolute;
  top: 93%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 0px 12px;
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
}

.custom-prev {
  left: 275px;
}

.custom-next {
  right: 10px;
}