// *************************Wear The Trend********************************//
@import "../css/Variable.scss";

.Flex-WearTrend_Lc {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.WearThe_theme3_Lc {
  width: 13%;
  // font-family: 'Poppins';
  // font-family: 'Rajdhani-Regular';
  padding-left: 20px;
  font-size: 50px;
  font-weight: 500;
  text-align: left;
  padding-top: 2%;
  // letter-spacing: -1px;
}

.Trend-MarginTop_Lc {
  margin-top: 1px;
  // font-family: 'Rajdhani-Regular';
  font-weight: bold;
}

.Trend-Hottest-Tag-Lc {
  font-family: "LKFuturaStd-Medium";
  font-size: 16px;
  line-height: 41px;
  font-weight: 400;
  margin-top: -5px;
}

.Flex_Slide1_Lc {
  display: flex;
  width: 80%;
  justify-content: space-between;
  overflow: hidden;
}

.LeftArrow_Lc {
  position: relative;
  width: 50px;
  text-indent: -9999px;
  font-size: 40px;
  // padding-top: 10%;
  cursor: pointer;
  height: 50px;
  // left: 48px;
  left: 21px;
  top: 94px;
}

.Img-Trend_Lc {
  background-size: 80%;
  background-position: center;
  // width: 300px;
  width: 235px;
  height: 250px;
  background-repeat: no-repeat;
  margin: auto;
}

.Home-Img-slider {
  background-size: 70%;
  //width: 260px;
  height: 240px;
  height: 210px;
}

.ExploreImg-width {
  width: 150px;
  height: 150px;
}

.Trend-Title_Lc {
  margin-bottom: 0px;
  font-family: "LKFuturaStd-Medium";
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0;
  font-family: "Montserrat", sans-serif;
  color: #443d3d;
}

.Trend-Explore_Lc {
  background: $Theme-color;
  color: #ffffff;
  padding: 10px 0;
  width: 140px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
  letter-spacing: 0.5px;
}

.Right-Arrow_Lc {
  position: relative;
  width: 50px;
  text-indent: -9999px;
  font-size: 40px;
  //padding-top: 10%;
  cursor: pointer;
  height: 50px;
  right: 48px;
  top: 94px;
}

.Flex-Explore_Lc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Banner-Slider_Lc {
  // background-image: url("https://www.ashishoptics.com/shop/images/banners/Banner733.jpg");
  width: 100%;
  height: 27vw;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-size: 100% 100%;
  cursor: pointer;
}

.Left-Arrow-Lc {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 5px;
  font-weight: 700;
  font-size: 45px;
  color: white;
  cursor: pointer;
  transform: translateY(-50%);
}

.Right-Arrow-Lc {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 5px;
  font-weight: 700;
  font-size: 45px;
  color: #d7d5d5;
  cursor: pointer;
  transform: translateY(-50%);
}

.Slider-Content {
  position: relative;
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.Title-Name_Lc {
  font-family: "Poppins";
  letter-spacing: 0.5px;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 94%;
  color: black;
}

.Title-Name_Lc:before,
.Title-Name_Lc:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #cfcdcd;
  margin: auto 15px;
}


.image-grid-container {
  width: 100%;
  // padding: 20px;
}

.title-container {
  margin-bottom: 20px;
  margin-top: 20px;
}

.image-grid {
  display: flex;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  
}

.image-block {
  position: relative;
  cursor: pointer;
  width: 45%;
}

.banner-style {
  width: 100%;
  height: auto;
}

.image-caption {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 4px;
}

// *************************Product Slider Images_Lc********************************//

.Flex-ProductSlider_Lc {
  //border-bottom: 1px solid #cfcdcd;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 84%;
  margin-top: 10px;
}

.ItemSlider-margin {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  //width: 90%;
  width: 100%;
  height: auto;
}

.ProductEyeglass-Font_Lc {
  font-size: 22px;
  font-family: LKFuturaStd-Medium, Arial, sans-serif;
  color: rgb(51, 51, 51);
  //color: #fe4d00;
  font-weight: 500;
}

.ViewRange-Font_Lc {
  font-size: 17px;
  font-weight: 500;
  color: rgb(50, 156, 146);
  font-family: LKFuturaStd-Medium, Arial, sans-serif;
}

.ProductImg-Margin_Lc {
  // margin: 30px 0px;
  margin: 5px 0px;
  width: 20%;
}

.PrevSlide_Lc {
  width: 100px;
  height: 80px;
  margin-top: 20px;
}

.NextSlide_Lc {
  width: 100px;
  height: 80px;
  margin-top: 20px;
}

.PrevSlide_Lc,
.NextSlide_Lc {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
}

.PrevSlide_Lc {
  left: 90px; /* Adjust this value to position it correctly */
}

.NextSlide_Lc {
  right: 90px; /* Adjust this value to position it correctly */
}
